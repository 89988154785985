




































import Vue from 'vue';
import Component from 'vue-class-component';
import {mdiGoogle} from '@mdi/js';
import {authApi} from '@/api';
import {ResourceType} from '@/enums';
import {authModule} from '@/store/modules';
import {AlertMessage} from '@/interfaces';

@Component
export default class Auth extends Vue {
  private googleIcon = mdiGoogle;
  private alert: AlertMessage | null = null;
  private loading = false;

  async created() {
    // we only need to check this single getter since if we lose Google Auth we
    // sign out the user from the API
    if (authModule.isAuthenticated) {
      const redirect = !this.$route.query.redirect
        ? 'dashboard'
        : decodeURIComponent(this.$route.query.redirect as string);
      await this.$router.push(redirect);
    }
  }

  async signIn() {
    this.loading = true;
    const googleUser = await this.$gAuth.signIn();
    if (!this.$gAuth.isAuthorized) {
      this.alert = {
        type: 'error',
        message: 'An error occurred during Google Sign In. Please try again.',
      };
      this.loading = false;
      return;
    }

    if (process.env.NODE_ENV === 'development') {
      console.debug('idToken', googleUser.getAuthResponse().id_token);
    }

    await authApi.post({
      type: ResourceType.Auth,
      attributes: {idToken: googleUser.getAuthResponse().id_token},
    });
    await authModule.checkAuth(this.$gAuth);

    if (authModule.isAuthenticated) {
      this.alert = {
        type: 'success',
        message: 'Redirecting to dashboard',
      };
      await this.$router.push({name: 'dashboard'});
    } else {
      this.alert = {
        type: 'error',
        message: 'An error occurred during sign in.',
      };
    }
    this.loading = false;
  }
}
